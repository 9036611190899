<template>
  <b-card>
    <div>
      <b-card-text>
        <h3>Screening Criterias</h3>
      </b-card-text>

      <!-- First Row -->
      <div class="row justify-content-center mt-4">
        <div class="col-md-2 mb-5">
          <h6>Market Capitalization:</h6>
            <b-form-group>
              <v-select
              v-model="marketcap"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="marketcap_option"
              
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>Cash Ratio:</h6>
            <b-form-group>
              <v-select
              v-model="cash_ratio"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="cash_ratio_options"
              @input="updateQuery"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>Current-Ratio:</h6>
            <b-form-group>
              <v-select
              v-model="current_ratio"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="current_ratio_options"
              @input="updateQuery"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>Revenue Growth</h6>
            <b-form-group>
              <v-select
              v-model="revenue_growth"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="revenue_growth_options"
              @input="updateQuery"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>Earnings Growth</h6>
            <b-form-group>
              <v-select
              v-model="earnings_growth"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="earnings_growth_options"
              @input="updateQuery"
            />
          </b-form-group>
        </div>
      </div>

      <!-- Second Row -->
      <div class="row justify-content-center">
        
        <div class="col-md-2 mb-5">
          <h6>FreeCashFlow Growth</h6>
            <b-form-group>
              <v-select
              v-model="fcf_growth"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="fcf_growth_options"
              @input="updateQuery"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>PE-Ratio</h6>
            <b-form-group>
              <v-select
              v-model="pe_ratio"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="pe_ratio_options"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>PB-Ratio</h6>
            <b-form-group>
              <v-select
              v-model="pb_ratio"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="pb_ratio_options"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>PS-Ratio</h6>
            <b-form-group>
              <v-select
              v-model="ps_ratio"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="ps_ratio_options"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-5">
          <h6>PEG-Ratio</h6>
            <b-form-group>
              <v-select
              v-model="peg_ratio"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="peg_ratio_options"
            />
          </b-form-group>
        </div>
      </div>

      <!-- Third Row -->
      <div class="row justify-content-center ">
        
        <div class="col-md-2 mb-2">
          <h6>Profit-Margin</h6>
            <b-form-group>
              <v-select
              v-model="profit_margin"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="profit_margin_options"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-2">
          <h6>FCF-Margin</h6>
            <b-form-group>
              <v-select
              v-model="fcf_margin"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="fcf_margin_options"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-2">
          <h6>Return on Assets</h6>
            <b-form-group>
              <v-select
              v-model="roa"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="roa_options"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-2">
          <h6>Return on Equity</h6>
            <b-form-group>
              <v-select
              v-model="roe"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="roe_options"
            />
          </b-form-group>
        </div>
        <div class="col-md-2 mb-2">
          <h6>Return on Capital</h6>
            <b-form-group>
              <v-select
              v-model="roic"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="roic_options"
            />
          </b-form-group>
        </div>
      </div>
      <b-row class="justify-content-center">
        <b-button @click="ReadCompanies" 
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      >
          Screen
        </b-button>
      </b-row>
      <b-row class="mt-4 justify-content-center">
        <p class="card-text">
          <small class="text-muted">
            These Values are what we believe to be the most important when analysing a companys. Keep in mind that this Screener
            <h6>DOES NOT REPLACE INVESTIGATING IN YOUR OWN RESEARCH</h6>
          </small>
        </p>
      </b-row>
    </div>
  </b-card>
</template>



<script>
import BCardCode from '@core/components/b-card-code'
import { BFormSpinbutton, BAlert, BCardText,BButton } from 'bootstrap-vue'
import { codeBasic } from './code'
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { db } from '@/firebase/index'
import { EventBus } from '@/main'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { collection, query, where, getDocs,limit } from "firebase/firestore";
import { title } from '@/@core/utils/filter'
import Ripple from 'vue-ripple-directive'




export default {
  components: {
    BFormSpinbutton,
    BCardCode,
    BCardText,
    BAlert,
    BRow, 
    BCol, 
    BFormGroup, 
    vSelect,
    BButton
  },
  directives: {
    Ripple,
  },
  computed: {
    joke() {
      return this.$store.getters.getCustomScreen
    },
    FireMarketCap() {
      if(this.marketcap.title === 'no limitiations') {
        return null
      }
      if(this.marketcap.title === "Large-Cap") {
        return where("Large_cap", "==", true)
      }
      if(this.marketcap.title === "Micro-Cap") {
        return where("Micro_cap", "==", true)
      }
      if(this.marketcap.title === "Small-Cap") {
        return where("Small_cap", "==", true)
      }
      if(this.marketcap.title === "Mid-Cap") {
        return where("Mid_cap", "==", true)
      }
    },
    FireCashRatio() {
      if(this.cash_ratio.title === 'no limitiations') {
        return null
      }
      if (this.cash_ratio.title === '>= 0.5') {
        return where("Cash_Ratio_5", "==", true)
      }
      if (this.cash_ratio.title === '>= 1') {
        return where("Cash_Ratio_10", "==", true)
      }
      if (this.cash_ratio.title === '>= 1.5') {
        return  where("Cash_Ratio_15", "==", true)
      }
      if (this.cash_ratio.title === '>= 2') {
        return where("Cash_Ratio_20", "==", true)
      }
      if (this.cash_ratio.title === '>= 2.5') {
        return where("Cash_Ratio_25", "==", true)
      }
    },
    FireCurrentRatio() {
    if (this.current_ratio.title === 'no limitations') {
      return null
    }
    if (this.current_ratio.title === '>= 0.5') {
      return where("Current_Ratio_5", "==", true);
    }
    if (this.current_ratio.title === '>= 1') {
      return where("Current_Ratio_10", "==", true);
    }
    if (this.current_ratio.title === '>= 1.5') {
      return where("Current_Ratio_15", "==", true);
    }
    if (this.current_ratio.title === '>= 2') {
      return where("Current_Ratio_20", "==", true);
    }
    if (this.current_ratio.title === '>= 2.5') {
      return where("Current_Ratio_25", "==", true);
    }
},
  FireRevenueGrowth() {
    if (this.revenue_growth.title === 'no limitations') {
      return null
    }
    if (this.revenue_growth.title === '>= 5%') {
       return where("Revenue_growth_5", "==", true);
    }
    if (this.revenue_growth.title === '>= 10%') {
      return where("Revenue_growth_10", "==", true);
    }
    if (this.revenue_growth.title === '>= 15%') {
      return where("Revenue_growth_15", "==", true);
    }
    if (this.revenue_growth.title === '>= 20%') {
      return where("Revenue_growth_20", "==", true);
    }
    if (this.revenue_growth.title === '>= 25%') {
      return where("Revenue_growth_25", "==", true);
    }
    if (this.revenue_growth.title === '>= 30') {
      return where("Revenue_growth_30", "==", true);
    }
  },
  FireEarningsGrowth() {
  if (this.earnings_growth.title === 'no limitations') {
    return null
  }
  if (this.earnings_growth.title === '>= 5%') {
    return where("Earnings_growth_5", "==", true);
  }
  if (this.earnings_growth.title === '>= 10%') {
    return where("Earnings_growth_10", "==", true);
  }
  if (this.earnings_growth.title === '>= 15%') {
    return where("Earnings_growth_15", "==", true);
  }
  if (this.earnings_growth.title === '>= 20%') {
    return where("Earnings_growth_20", "==", true);
  }
  if (this.earnings_growth.title === '>= 25%') {
    return where("Earnings_growth_25", "==", true);
  }
  if (this.earnings_growth.title === '>= 30') {
    return where("Earnings_growth_30", "==", true);
  }
},
FireFCFGrowth() {
  if (this.fcf_growth.title === 'no limitations') {
    return null
  }
  if (this.fcf_growth.title === '>= 5%') {
    return where("FCF_growth_5", "==", true);
  }
  if (this.fcf_growth.title === '>= 10%') {
    return where("FCF_growth_10", "==", true);
  }
  if (this.fcf_growth.title === '>= 15%') {
    return where("FCF_growth_15", "==", true);
  }
  if (this.fcf_growth.title === '>= 20%') {
    return where("FCF_growth_20", "==", true);
  }
  if (this.fcf_growth.title === '>= 25%') {
    return where("FCF_growth_25", "==", true);
  }
  if (this.fcf_growth.title === '>= 30') {
    return where("FCF_growth_30", "==", true);
  }
},
  FirePERatio() {
    if (this.pe_ratio.title === 'no limitations') {
      return null
    }
    if (this.pe_ratio.title === '<= 5') {
      return where("PE_5", "==", true);
    }
    if (this.pe_ratio.title === '<= 10') {
      return where("PE_10", "==", true);
    }
    if (this.pe_ratio.title === '<= 15') {
      return where("PE_15", "==", true);
    }
    if (this.pe_ratio.title === '<= 20') {
      return where("PE_20", "==", true);
    }
    if (this.pe_ratio.title === '<= 25') {
      return where("PE_25", "==", true);
    }
    if (this.pe_ratio.title === '<= 30') {
      return where("PE_30", "==", true);
    }
  },
  FirePBRatio() {
    if (this.pb_ratio.title === 'no limitations') {
      return null;
    }
    if (this.pb_ratio.title === '<= 5') {
      return where("PB_5", "==", true);
    }
    if (this.pb_ratio.title === '<= 10') {
      return where("PB_10", "==", true);
    }
    if (this.pb_ratio.title === '<= 15') {
      return where("PB_15", "==", true);
    }
    if (this.pb_ratio.title === '<= 20') {
      return where("PB_20", "==", true);
    }
    if (this.pb_ratio.title === '<= 25') {
      return where("PB_25", "==", true);
    }
    if (this.pb_ratio.title === '<= 30') {
      return where("PB_30", "==", true);
    }
  },
  FirePSRatio() {
    if (this.ps_ratio.title === 'no limitations') {
      return null;
    }
    if (this.ps_ratio.title === '<= 0.5') {
      return where("PS_5", "==", true);
    }
    if (this.ps_ratio.title === '<= 1.0') {
      return where("PS_10", "==", true);
    }
    if (this.ps_ratio.title === '<= 1.5') {
      return where("PS_15", "==", true);
    }
    if (this.ps_ratio.title === '<= 2.0') {
      return where("PS_20", "==", true);
    }
    if (this.ps_ratio.title === '<= 2.5') {
      return where("PS_25", "==", true);
    }
    if (this.ps_ratio.title === '<= 3.0') {
      return where("PS_30", "==", true);
    }
  },
  FirePEGRatio() {
    if (this.peg_ratio.title === 'no limitations') {
      return null;
    }
    if (this.peg_ratio.title === '<= 0.5') {
      return where("PEG_5", "==", true);
    }
    if (this.peg_ratio.title === '<= 1.0') {
      return where("PEG_10", "==", true);
    }
    if (this.peg_ratio.title === '<= 1.5') {
      return where("PEG_15", "==", true);
    }
    if (this.peg_ratio.title === '<= 2.0') {
      return where("PEG_20", "==", true);
    }
    if (this.peg_ratio.title === '<= 2.5') {
      return where("PEG_25", "==", true);
    }
  },

  FireProfitMargin() {
    if (this.profit_margin.title === 'no limitations') {
      return null;
    }
    if (this.profit_margin.title === '>= 5%') {
      return where("ProfitMargin_5", "==", true);
    }
    if (this.profit_margin.title === '>= 10%') {
      return where("ProfitMargin_10", "==", true);
    }
    if (this.profit_margin.title === '>= 15%') {
      return where("ProfitMargin_15", "==", true);
    }
    if (this.profit_margin.title === '>= 20%') {
      return where("ProfitMargin_20", "==", true);
    }
    if (this.profit_margin.title === '>= 25%') {
      return where("ProfitMargin_25", "==", true);
    }
    if (this.profit_margin.title === '>= 30') {
      return where("ProfitMargin_30", "==", true);
    }
  },
  FireFCFMargin() {
    if (this.fcf_margin.title === 'no limitations') {
      return null;
    }
    if (this.fcf_margin.title === '>= 5%') {
      return where("FCF_Margin_5", "==", true);
    }
    if (this.fcf_margin.title === '>= 10%') {
      return where("FCF_Margin_10", "==", true);
    }
    if (this.fcf_margin.title === '>= 15%') {
      return where("FCF_Margin_15", "==", true);
    }
    if (this.fcf_margin.title === '>= 20%') {
      return where("FCF_Margin_20", "==", true);
    }
    if (this.fcf_margin.title === '>= 25%') {
      return where("FCF_Margin_25", "==", true);
    }
    if (this.fcf_margin.title === '>= 30') {
      return where("FCF_Margin_30", "==", true);
    }
  },
  FireROA() {
    if (this.roa.title === 'no limitations') {
      return null;
    }
    if (this.roa.title === '>= 5%') {
      return where("ROA_5", "==", true);
    }
    if (this.roa.title === '>= 10%') {
      return where("ROA_10", "==", true);
    }
    if (this.roa.title === '>= 15%') {
      return where("ROA_15", "==", true);
    }
    if (this.roa.title === '>= 20%') {
      return where("ROA_20", "==", true);
    }
    if (this.roa.title === '>= 25%') {
      return where("ROA_25", "==", true);
    }
    if (this.roa.title === '>= 30') {
      return where("ROA_30", "==", true);
    }
  },
  FireROE() {
    if (this.roe.title === 'no limitations') {
      return null;
    }
    if (this.roe.title === '>= 5%') {
      return where("ROE_5", "==", true);
    }
    if (this.roe.title === '>= 10%') {
      return where("ROE_10", "==", true);
    }
    if (this.roe.title === '>= 15%') {
      return where("ROE_15", "==", true);
    }
    if (this.roe.title === '>= 20%') {
      return where("ROE_20", "==", true);
    }
    if (this.roe.title === '>= 25%') {
      return where("ROE_25", "==", true);
    }
    if (this.roe.title === '>= 30') {
      return where("ROE_30", "==", true);
    }
  },
  FireROIC() {
  if (this.roic.title === 'no limitations') {
    return null;
  }
  if (this.roic.title === '>= 5%') {
    return where("ROIC_5", "==", true);
  }
  if (this.roic.title === '>= 10%') {
    return where("ROIC_10", "==", true);
  }
  if (this.roic.title === '>= 15%') {
    return where("ROIC_15", "==", true);
  }
  if (this.roic.title === '>= 20%') {
    return where("ROIC_20", "==", true);
  }
  if (this.roic.title === '>= 25%') {
    return where("ROIC_25", "==", true);
  }
  if (this.roic.title === '>= 30') {
    return where("ROIC_30", "==", true);
  }
},











  },
  data() {
    return {
      marketcap: { title: 'no limitiations' },
      marketcap_option: [{title: 'no limitiations'},{ title: 'Micro-Cap' }, { title: 'Small-Cap' }, { title: 'Mid-Cap' }, { title: 'Large-Cap' }],
      
      cash_ratio: { title: 'no limitiations' },
      cash_ratio_options: [{title: 'no limitiations'},{ title: '>= 0.5' }, { title: '>= 1' }, { title: '>=1.5 '}, { title: '>= 2' }, {title: '>=2.5'}],
      
      current_ratio: { title: 'no limitiations' },
      current_ratio_options: [{title: 'no limitiations'},{ title: '>= 0.5' }, { title: '>= 1' }, { title: '>=1.5 '}, { title: '>= 2' }, {title: '>=2.5'}],
      
      revenue_growth: { title: 'no limitiations' },
      revenue_growth_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
      
      earnings_growth: { title: 'no limitiations' },
      earnings_growth_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
      
      fcf_growth: { title: 'no limitiations' },
      fcf_growth_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
     
      pe_ratio: { title: 'no limitiations' },
      pe_ratio_options: [{title: 'no limitiations'},{ title: '<= 5' }, { title: '<= 10' }, { title: '<= 15'}, { title: '<= 20' },{ title: '<= 25' },{ title: '<= 30' }],
      
      pb_ratio: { title: 'no limitiations' },
      pb_ratio_options: [{title: 'no limitiations'},{ title: '<= 5' }, { title: '<= 10' }, { title: '<= 15'}, { title: '<= 20' },{ title: '<= 25' },{ title: '<= 30' }],
      
      ps_ratio: { title: 'no limitiations' },
      ps_ratio_options: [{title: 'no limitiations'},{title: 'no limitiations'},{ title: '<= 0.5' }, { title: '<= 1.0' }, { title: '<= 1.5'}, { title: '<= 2.0' },{ title: '<= 2.5' },{ title: '<= 3.0' }],
     
      peg_ratio: { title: 'no limitiations' },
      peg_ratio_options: [{title: 'no limitiations'},{ title: '<= 0.5' }, { title: '<= 1.0' }, { title: '<= 1.5'}, { title: '<= 2.0' },{ title: '<= 2.5' },{ title: '<= 3.0' }],
      
      profit_margin: { title: 'no limitiations' },
      profit_margin_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
      
      fcf_margin: { title: 'no limitiations' },
      fcf_margin_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
      
      roa: { title: 'no limitiations' },
      roa_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
      
      roe: { title: 'no limitiations' },
      roe_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
      
      roic: { title: 'no limitiations' },
      roic_options: [{title: 'no limitiations'},{ title: '>= 5%' }, { title: '>= 10%' }, { title: '>= 15% '}, { title: '>= 20%' },{ title: '>= 25%' },{ title: '>= 30' }],
      
 }
  
  },
  methods: {
    async ReadCompanies() {
  const table = [];
  const conditions = [
    this.FireMarketCap,
    this.FireCashRatio,
    this.FireCurrentRatio,
    this.FireRevenueGrowth,
    this.FireEarningsGrowth,
    this.FireFCFGrowth,
    this.FirePERatio,
    this.FirePBRatio,
    this.FirePSRatio,
    this.FirePEGRatio,
    this.FireProfitMargin,
    this.FireFCFMargin,
    this.FireROA,
    this.FireROE,
    this.FireROIC
  ];

  const fields = {
    "Market-Cap": "MarketCap",
    "Cash-Ratio": "CashRatio",
    "Current-Ratio": "CurrentRatio",
    "Revenue-Growth": "RevenueGrowth",
    "Earnings-Growth": "EarningsGrowth",
    "FCF-Growth": "FcfGrowth",
    "PE-Ratio": "PE-Ratio",
    "PB-Ratio": "PB-Ratio",
    "PS-Ratio": "PS-Ratio",
    "PEG-Ratio": "PEG-Ratio",
    "Profit-Margin": "Profit-Margin",
    "FCF-Margin": "FreeCashflow-Margin",
    "ROA": "ROA",
    "ROE": "ROE",
    "ROIC": "ROIC",
  };

  const nonEmptyConditions = [];
  for (const condition of conditions) {
    if (condition !== null && condition !== undefined) {
      nonEmptyConditions.push(condition);
    }
  }

  const q = query(collection(db, "companies"), ...nonEmptyConditions, limit(15));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const data = { "Name": doc.id };

    conditions.forEach((condition, index) => {
      if (condition !== null && condition !== undefined) {
        const field = Object.keys(fields)[index];
        const computedStatName = fields[field];
        const computedStat = doc.data()[computedStatName];

        // Check if it's one of the desired fields
        if (["RevenueGrowth", "EarningsGrowth", "FCFGrowth"].includes(computedStatName)) {
          // Calculate the average of the last three values
          const lastThreeValues = this.getLastThreeValues(doc.data(), computedStatName);
          const average = this.calculateAverage(lastThreeValues);
          data[computedStatName] = average;
        } else {
          // Check if it's the MarketCap field
          if (computedStatName === "MarketCap") {
            // For MarketCap, use the original value without rounding
            data[computedStatName] = computedStat;
          } else {
            // For other fields, round to 1 decimal place
            data[computedStatName] = Math.round(computedStat * 10) / 10;
          }
        }
      }
    });

    table.push(data);
    console.log(data);
  });

  this.$store.dispatch('setCustomScreen', table);
},


  getLastThreeValues(data, statName) {
      const allValues = data[statName];
      const years = Object.keys(allValues);

      // Sortieren Sie die Jahre in aufsteigender Reihenfolge
      const sortedYears = years.sort((a, b) => parseInt(a) - parseInt(b));

      // Extrahieren Sie die letzten drei Jahre
      const lastThreeYears = sortedYears.slice(-3);

      // Extrahieren Sie die Werte für die letzten drei Jahre
      const lastThreeValues = lastThreeYears.map(year => allValues[year]);

      return lastThreeValues;
    },

    calculateAverage(values) {
      let sum = values.reduce((acc, value) => acc + value, 0);
      let average = sum / values.length;
      let roundedAverage = Math.round(average * 10) / 10; // Runde auf eine Nachkommastelle
      let averageString = roundedAverage.toFixed(1) + '%'; // Konvertiere in String und füge das Prozentzeichen hinzu
      return averageString;
    }
}
}
</script>

