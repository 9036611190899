<template>
    <section id="ScreenHeader">
      <b-row>
        <!-- left align -->
        <b-col
          lg="12"
        >
          <b-card
            notitle 
            class="mb-2 text-center"
          >
            <b-card>
                <b-row>
                    <b-col lg="12" md="6">
                        <form-spinbutton-basic-vue />    
                    </b-col>
                </b-row>
            </b-card>
          </b-card>

        </b-col>
        <b-col>
            
        </b-col>
      </b-row>
      <b-card>
        <b-row lg="12">
        <company-list-vue  lg="12"/>
      </b-row>
      </b-card>
    </section>
  </template>
  
  <script>
  import {
    BRow, BCol, BCard, BCardText, BButton,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import FormSpinbuttonBasicVue from './FormSpinbuttonBasic.vue'
  import CardTextAligntmentVue from "./CardTextAlignment.vue"
  import CompanyListVue from './CompanyList.vue'
  
  export default {
    components: {
      BRow, BCol, BCard, BCardText, BButton,
      FormSpinbuttonBasicVue,
      CardTextAligntmentVue,
      CompanyListVue
    },
    directives: {
      Ripple,
    
    },
  }
  </script>