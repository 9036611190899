<template>
    
      <b-card class="h-200">
        <b-row>
            <b-col lg="12" sm="2">
                <screen-header-vue />
            </b-col>
            <b-col lg="">
              
            </b-col>

        </b-row>
      </b-card>
  
    
</template>




<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'

import CardTextAlignmentVue from './CardTextAlignment.vue'
import FromInputSpinbuttonbasic from './FormSpinbuttonBasic.vue'
import ScreenHeaderVue from './ScreenHeader.vue'

import { db } from '@/firebase/index'
import { EventBus } from '@/main'
import { doc, getDoc, getDocs, collection } from "firebase/firestore";





export default {
    components: {
        BRow, 
        BCol, 
        BLink,
        CardTextAlignmentVue,
        FromInputSpinbuttonbasic,
        ScreenHeaderVue,
        document,
    },
    // data () {
    //     return {
    //       compdata:[],
    //       compName: '',
    //       data,
    //     }
    //   },
    // created() {
    // // this.getCompany(),
    // EventBus.$on('suggestionSelected',(data) => {
    // this.compName= data.name
    // this.getCompany()
    // });
    // },
    // methods: {
    // async getCompany() {

    //   const docSnap = await getDoc(doc(db, 'companies', this.compName))

    //   if (docSnap.exists()) {
    //     // assign document fields
    //     // to data properties
    //     console.log(docSnap.data())
    //     const data = docSnap.data()
    //     this.compdata.push(data)
    //   } else {
    //     console.log('Document does not exist')
    //   } 
    // }


    
    
//   }
 }
</script>

<!-- <template>
  <div>
    <b-text>
        sadhaosdiu
    </b-text>
  </div>
</template>

<script>
// relevant methods
import { BRow, BCol, BLink } from 'bootstrap-vue'
import { doc, getDoc } from "firebase/firestore"
import db from '@/firebase/index.js'

export default {
    BRow,
    BCol,
    BLink,
  created() {
    this.getCountry()
  },
  methods: {
    async getCountry() {

      const docSnap = await getDoc(doc(db, 'companies', 'Microsoft Corporation'))

      if (docSnap.exists()) {
        console.log(docSnap.data())
      } else {
        console.log('Document does not exist')
      }

    }
  }
}
</script> -->