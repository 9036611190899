<template>
  <div>
    <section id="card-text-alignment">
      <b-row class="">
        <!-- left align -->
        <b-col
          lg="12"
        >
          <b-card 
            title="How to find the companies you're looking for"
            class="mb-0 mt-20"
          >
            <b-card-text class="mt-20">
              You might be familiar with the Terms: Current Ratio, Price to Earnings Ratio and the Price to Earnings Ratio,. If you do not know them yet, you can click below to learn what these terms mean and why the are the most relevant to measure profitability of a specific Company.
            </b-card-text>
            
             <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-center
              variant="outline-primary"
            >
              Explain it to me
            </b-button>
            <b-modal
              id="modal-center"
              centered
              title="Vertically Centered"
              ok-only
              ok-title="Accept"
            >
              <b-card-text>
              3-Year Revenue Growth:
          Imagine you have a lemonade stand, and each year more people come to buy your lemonade. That's like revenue growth—it's the increase in money the company makes over three years. Positive growth means the company is getting more popular and making more sales.
          Market Capitalization:
          Think of market capitalization as the size of a company. If the company were a pizza, market cap would be the total value of that pizza. It's calculated by multiplying the number of pizza slices (shares) by the price of each slice. Big companies have big pizzas, and small companies have small pizzas.
          PEG Ratio (Price/Earnings to Growth Ratio):
          This is like checking the speed of a car (P/E ratio) and also considering how fast it might get in the future (earnings growth). If the car is fast and getting faster, it's usually a good sign. A PEG ratio below 1 suggests you're getting good speed for your money.
          Current Ratio:
          Imagine you have a jar of cookies (current assets) and you owe your friend some cookies (current liabilities). The current ratio is like checking if you have enough cookies in your jar to pay your friend. If you have more cookies than you owe, you're in good shape. Similarly, a high current ratio means the company has more assets than liabilities in the short term.

              </b-card-text>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
  
</template>

<script>
import {  doc, getDoc } from "firebase/firestore"
import { onBeforeMount } from "vue-demi"
import  {db}  from '@/firebase/index.js'
 import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { BModal, VBModal, BAlert} from 'bootstrap-vue'



export default {

  components: {
    BRow, BCol, BCard, BCardText, BButton,BModal, VBModal, BAlert
  },
  directives: {
    Ripple,'b-modal': VBModal,
  
  },
}
</script>
